import Base from './base.js';

export default class Part extends Base {
	constructor() {
		super();
		this.route = '/api/part';
	}

	all() {
		let self = this;
		return new Promise(function(resolve, reject) {
			self
				.Axios({ url: self.route, method: 'GET' })
				.then(response => {
					// Resolve successfully
					resolve(response.data);
				})
				.catch(response => {
					reject(self.parseErrorResponses(error.response.data));
				});
		});
	}

	byCategoryID(id) {
		let self = this;
		return new Promise(function(resolve, reject) {
			self
				.Axios({ url: self.route + `/category/${id}`, method: 'GET' })
				.then(response => {
					// Resolve successfully
					resolve(response.data);
				})
				.catch(response => {
					reject(self.parseErrorResponses(error.response.data));
				});
		});
	}

	detail(id) {
		let self = this;
		return new Promise(function(resolve, reject) {
			self
				.Axios({ url: self.route + `/${id}`, method: 'GET' })
				.then(response => {
					// Resolve successfully
					resolve(response.data);
				})
				.catch(response => {
					reject(self.parseErrorResponses(error.response.data));
				});
		});
	}

	create(data, image) {
		console.log('part creation');
		let self = this;
		const formData = new FormData();

		// Add images to form data
		formData.append('id', data.id);
		formData.append('name', data.name);
		formData.append('part_category_id', data.part_category_id);
		formData.append('new_image', image);

		return new Promise(function(resolve, reject) {
			self
				.Axios({
					url: self.route,
					data: formData,
					method: 'POST',
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(response => {
					console.log(response.data);
					resolve(response.data);
				})
				.catch(error => {
					reject(self.parseErrorResponses(error.response.data));
				});
		});
	}

	update(data, new_image) {
		console.log('update part');
		console.log(data);
		let self = this;
		const formData = new FormData();

		// Add images to form data
		formData.append('id', data.id);
		formData.append('name', data.name);
		formData.append('part_category_id', data.part_category_id);
		formData.append('image', data.image);
		if (typeof new_image == 'undefined') {
			formData.append('new_image', '');
		} else {
			formData.append('new_image', new_image);
		}

		formData.append('_method', 'PUT');

		return new Promise(function(resolve, reject) {
			self
				.Axios({
					url: self.route + `/${data.id}`,
					data: formData,
					method: 'POST',
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(response => {
					console.log(response.data);
					resolve(response.data);
				})
				.catch(error => {
					reject(self.parseErrorResponses(error.response.data));
				});
		});
	}

	delete(id) {
		let self = this;
		return new Promise(function(resolve, reject) {
			self
				.Axios({ url: self.route + `/${id}`, method: 'DELETE' })
				.then(response => {
					// Resolve successfully
					resolve();
				})
				.catch(response => {
					console.log(response.error);
					reject(false);
				});
		});
	}
}
