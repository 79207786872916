<template>
  <CModal v-if="showModal" :show.sync="showModal" :no-close-on-backdrop="true" :centered="true" @hide="hide()" size="lg"
    color="primary">
    <template #header>
      <h2 class="modal-title">
        New Defect
      </h2>
      <CButtonClose @click="hide()" class="text-white" />
    </template>
    <CRow class="justify-content-center mb-4">
      <CCol class='text-center' sm="12">
        <h2>1. Select Product</h2>
      </CCol>
      <CCol class='justify-content-center' sm="6">
        <v-select v-model="defect.product_id" :reduce="(product) => product.id" label="label" :options="products"
          @input="getPartCategories()"></v-select>
      </CCol>
    </CRow>
    <CRow class="parts justify-content-center mb-4">
      <CCol sm="12 text-center">
        <h2>2. Select Category</h2>
      </CCol>
      <CCol class="part p-2" sm="2" v-for="part_category in part_categories" :key="part_category.id"
        @click="defect.part_category_id = part_category.id; getParts()"
        :class="defect.part_category_id == part_category.id ? 'active' : ''">
        <img v-if="part_category.image && part_category.image != 'null'" width="40" class=''
          :src="storageURL + part_category.image" />
        <CIcon v-else width="40" class="close-btn" name="cil-plus" />
        <p>{{ part_category.name }}</p>
      </CCol>
    </CRow>
    <CRow class="parts justify-content-center mb-4">
      <CCol class='text-center' sm="12">
        <h2>3. Select Part</h2>
      </CCol>
      <CCol class="part p-2" sm="2" v-for="part in parts" :key="part.id" @click="defect.part_id = part.id"
        :class="defect.part_id == part.id ? 'active' : ''">
        <img v-if="part.image && part.image != 'null'" width="40" class='' :src="storageURL + part.image" />
        <CIcon v-else width="40" class="close-btn" name="cil-plus" />
        <p>{{ part.name }}</p>
      </CCol>
    </CRow>
    <CRow class='justify-content-center mb-4'>
      <CCol class='text-center' sm="12">
        <h2>4. Add A Note</h2>
      </CCol>
      <CCol sm="6">
        <CTextarea description="This note will be placed on the defect information" v-model="defect.note" />
        </CTextarea>
      </CCol>
    </CRow>
    <template #footer>
      <CButton @click="hide()" color="danger">Cancel</CButton>
      <CButton :disabled="defect.part_id ? false : true" @click="process()" color="success">Process</CButton>
    </template>
  </CModal>
</template>

<script>
import ProductAPI from "/app/src/api/product.js";
import PartAPI from "/app/src/api/part.js";
import PartCategoryAPI from "/app/src/api/part-category.js";
import DefectAPI from "/app/src/api/defect.js";

export default {
  name: "Defects",
  props: ["data", "show"],
  data: () => {
    return {
      showModal: false,
      productAPI: new ProductAPI(),
      partAPI: new PartAPI(),
      partCategoryAPI: new PartCategoryAPI(),
      defectAPI: new DefectAPI(),
      products: [],
      parts: [],
      part_categories: [],
      defects: [],
      defect: {
        product_id: null,
        part_id: null,
        part: {
          part_category_id: null,
          part_id: null,
        },
        note: ''
      },
      storageURL: process.env.VUE_APP_STORAGE_URL,
    };
  },
  created: function () {
    this.getProducts();
    this.showModal = this.show;
  },
  methods: {
    getProducts: function () {
      this.$store.dispatch("loading");
      let self = this;
      this.productAPI
        .all()
        .then((products) => {
          this.$store.dispatch("stopLoading");
          for (var i = 0; i < products.length; i++) {
            products[i].label = `${products[i].type} - #${products[i].number}`;
          }
          self.products = products;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },
    getPartCategories: function () {
      this.$store.dispatch("loading");
      let self = this;
      this.partCategoryAPI
        .all()
        .then((part_categories) => {
          this.$store.dispatch("stopLoading");
          self.part_categories = part_categories;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },
    getParts: function () {
      this.$store.dispatch("loading");
      let self = this;
      this.partAPI
        .byCategoryID(this.defect.part_category_id)
        .then((parts) => {
          this.$store.dispatch("stopLoading");
          self.parts = parts;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },

    process() {
      this.$store.dispatch('loading')
      let self = this
      this.defectAPI.create(self.defect)
        .then((defect) => {
          this.$store.dispatch('stopLoading')
          this.$emit("input", defect);
          this.defect = {
            part_category_id: null,
            part_id: null,
            note: ''
          };
          this.$emit("hide");
        })
        .catch((error) => {
          this.$store.dispatch('stopLoading')
          this.$alert.show({ type: 'danger', 'message': error })
        })

    },

    hide() {
      this.$emit("hide");
    },
  },
  watch: {
    show(val, oldVal) {
      this.showModal = val;
    },
    showModal(val, oldVal) {
      if (val == false) {
        this.hide();
      }
    }
  },
};
</script>

<style scoped>
.part {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  text-align: center;
  border: 2px solid #303c54;
  margin: 5px;
  align-items: center;
  border-radius: 0.25rem;
}

.part.active {
  background-color: #2F3C54;
  color: white;
}

.part p {
  font-weight: bold;
  font-size: 1.2rem;
  width: 100%;
  margin-bottom: 0px;
}
</style>
