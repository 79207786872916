import Base from './base.js';

export default class Defect extends Base {
	constructor() {
		super();
		this.route = '/api/defect';
	}

	all() {
		let self = this;
		return new Promise(function(resolve, reject) {
			self
				.Axios({ url: self.route, method: 'GET' })
				.then(response => {
					// Resolve successfully
					resolve(response.data);
				})
				.catch(response => {
					reject(self.parseErrorResponses(error.response.data));
				});
		});
	}

	detail(id) {
		let self = this;
		return new Promise(function(resolve, reject) {
			self
				.Axios({ url: self.route + `/${id}`, method: 'GET' })
				.then(response => {
					// Resolve successfully
					resolve(response.data);
				})
				.catch(response => {
					reject(self.parseErrorResponses(error.response.data));
				});
		});
	}

	mailToAdmin(id) {
		let self = this;
		return new Promise(function(resolve, reject) {
			self
				.Axios({ url: self.route + `/mail-to-admin`, method: 'GET' })
				.then(response => {
					// Resolve successfully
					resolve(response.data);
				})
				.catch(response => {
					reject(self.parseErrorResponses(error.response.data));
				});
		});
	}

	create(data, image) {
		console.log('defect creation');
		let self = this;
		const formData = new FormData();

		formData.append('id', data.id);
		formData.append('note', data.note);
		formData.append('product_id', data.product_id);
		formData.append('part_id', data.part_id);

		return new Promise(function(resolve, reject) {
			self
				.Axios({
					url: self.route,
					data: formData,
					method: 'POST',
					headers: {
						'Content-Type': 'multidefect/form-data',
					},
				})
				.then(response => {
					console.log(response.data);
					resolve(response.data);
				})
				.catch(error => {
					reject(self.parseErrorResponses(error.response.data));
				});
		});
	}

	createMultiple(data) {
		let self = this;
		const formData = new FormData();

		formData.append('defects', JSON.stringify(data));

		return new Promise(function(resolve, reject) {
			self
				.Axios({
					url: self.route + '/multiple',
					data: formData,
					method: 'POST',
					headers: {
						'Content-Type': 'multidefect/form-data',
					},
				})
				.then(response => {
					console.log(response.data);
					resolve(response.data);
				})
				.catch(error => {
					reject(self.parseErrorResponses(error.response.data));
				});
		});
	}

	update(data, image) {
		let self = this;
		const formData = new FormData();

		// Add images to form data
		formData.append('id', data.id);
		formData.append('status', data.status);
		formData.append('note', data.note);
		formData.append('product_id', data.product_id);
		formData.append('part_id', data.part_id);

		formData.append('_method', 'PUT');

		return new Promise(function(resolve, reject) {
			self
				.Axios({
					url: self.route + `/${data.id}`,
					data: formData,
					method: 'POST',
					headers: {
						'Content-Type': 'multidefect/form-data',
					},
				})
				.then(response => {
					console.log(response.data);
					resolve(response.data);
				})
				.catch(error => {
					reject(self.parseErrorResponses(error.response.data));
				});
		});
	}

	delete(id) {
		let self = this;
		return new Promise(function(resolve, reject) {
			self
				.Axios({ url: self.route + `/${id}`, method: 'DELETE' })
				.then(response => {
					// Resolve successfully
					resolve();
				})
				.catch(response => {
					console.log(response.error);
					reject(false);
				});
		});
	}
}
