<template>
  <CRow>
    <CCol col="12" xl="12">
      <CButton variant="outline" type="submit" color="primary" class="list-new-btn" @click="showDefectsModal = true" v-if="$store.getters.isAdmin ||
        $store.getters.isLocationElite ||
        $store.getters.isLocationAdmin
        ">
        <CIcon name="cil-check-circle" />
        New defect
      </CButton>

      <modal :show="showDefectsModal" @input="items.unshift($event)" @hide="showDefectsModal = false"></modal>

      <CButton variant="outline" type="submit" color="primary" class="list-new-btn" :show="true" @click="mailToAdmin()"
        v-if="$store.getters.isAdmin">
        <CIcon name="cil-check-circle" />
        Mail Open Defects
      </CButton>

      <transition name="slide">
        <CCard>
          <CCardHeader>
            <CCardTitle>Defects</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CDataTable hover sorter striped table-filter :items="items" :fields="fields" :items-per-page="perPage"
              :pagination="$options.paginationProps" index-column>
              <template #status="{ item }">
                <td>
                  <CBadge :color="getBadge(item.status)">
                    {{ item.status }}
                  </CBadge>
                </td>
              </template>
              <template #number="{ item }">
                <td class="py-2">#{{ item.number }}</td>
              </template>
              <template #location="{ item }">
                <td class="py-2">
                  {{ item.product.location.name }}
                </td>
              </template>
              <template #part_name="{ item }">
                <td class="py-2">
                  {{ item.part.name }}
                </td>
              </template>
              <template #product_type="{ item }">
                <td class="py-2">
                  {{ item.product.type }}
                </td>
              </template>
              <template #part_category="{ item }">
                <td class="py-2">
                  {{ item.part.part_category.name }}
                </td>
              </template>
              <template #note="{ item }">
                <td class="py-2">
                  {{ item.note }}
                </td>
              </template>
              <template #created_at="{ item }">
                <td class="py-2">
                  {{ item.created_at | dateToDateTimeString }}
                </td>
              </template>
              <template #actions="{ item, index }">
                <td class="py-2">
                  <CButton color="primary" variant="outline" square size="sm" @click="detailClicked(item, index)">
                    Details
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import DefectAPI from "/app/src/api/defect.js"
import Modal from "./Modal"

export default {
  name: "Defects",
  components: { Modal },
  data: () => {
    return {
      defectAPI: new DefectAPI(),
      items: [],
      fields: [
        { key: "number" },
        { key: "location_name" },
        { key: "status" },
        { key: "part_category" },
        { key: "part_name" },
        { key: "note" },
        { key: "created_at" },
        { key: "actions" },
      ],
      perPage: 20,
      showDefectsModal: false,
    }
  },
  paginationProps: {
    align: "center",
    doubleArrows: false,
    previousButtonHtml: "prev",
    nextButtonHtml: "next",
  },
  created: function () {
    this.getDefects()
  },
  methods: {
    getDefects: function () {
      this.$store.dispatch("loading")
      let self = this
      this.defectAPI
        .all()
        .then((defects) => {
          this.$store.dispatch("stopLoading")
          for (let i = 0; i < defects.length; i++) {
            defects[i].location_name = defects[i].product.location.name
            defects[i].number = defects[i].product.number
          }
          self.items = defects

        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          console.log(error)
        })
    },
    mailToAdmin: function () {
      this.$store.dispatch("loading")
      let self = this
      this.defectAPI
        .mailToAdmin()
        .then(() => {
          this.$store.dispatch("stopLoading")
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          console.log(error)
        })
    },
    getBadge(status) {
      return status === "repaired"
        ? "success"
        : status === "open"
          ? "danger"
          : "primary"
    },
    detailClicked(item, index) {
      const itemLink = `/manager/defects/${item.id.toString()}`
      this.$router.push({ path: itemLink })
    },
  },
}
</script>
